header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}
/*CTA*/
.CTA {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*Socials*/
.header__socials {

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/*ME*/
.me {
    /* background: linear-gradient(var(--color-primary), transparent); */
    width: 28rem;
    height: 36rem;
    position: absolute;
    left: calc(50% - 15rem);
    margin-top: 0rem;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}
/*Scroll Down*/
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/*Media Queries (Medium Devices)*/
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;

    }
    .me{
        display: none;
        /*background: linear-gradient(var(--color-primary), transparent);
        width: 22rem;
        height: 18rem;
        position: absolute;
        left: calc(50% - 11rem);
        margin-top: 4rem;
        border-radius: 12rem 12rem 0 0;
        overflow: hidden;
        padding: 1rem 1.5rem 1.5rem 1.5rem; */
    }
    .photo
    {
        position: absolute;
        left: calc(50% - 5rem);
        width: 10rem;
    }
}

/*Media Queries (Small Devices)*/
@media screen and (max-width: 600px) {
    header{
        height: 90vh;
    }
    .me{
        display: none;
        /*background: linear-gradient(var(--color-primary), transparent);
        width: 22rem;
        height: 18rem;
        position: absolute;
        left: calc(50% - 11rem);
        margin-top: 4rem;
        border-radius: 12rem 12rem 0 0;
        overflow: hidden;
        padding: 1rem 1.5rem 1.5rem 1.5rem;*/
    }
    .photo
    {
        position: absolute;
        left: calc(50% - 5rem);
        width: 10rem;
    }
    .header__socials, .scroll__down {
        display: none;
    }
}